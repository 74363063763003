<template>
  <sign-page
    title-text="文章管理"
    :request="request"
    table-size="large"
    ref="articleTypePage"
    :titleMenus="[{key: 'new', label: '新文章'}]"
    @clickTitleMenu="clickTitleMenu"
    @tableAction="tableAction"
    :column-list="columnList"
    :table-actions-fixed="true"
    :tableActions="tableActions"
    :tableActionsWidth="200"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modal" v-if="modal" width="90%" title="文章详细">
      <article-form
        :source-data="chooseData"
        ref="articleForm"
        :article-type-list="articleTypeList"
        :user-list="$store.getters.workerUserList">
      </article-form>
      <div class="btns">
        <fm-btn @click="save" style="margin-right:50px;">保存</fm-btn>
        <fm-btn @click="modal = false">取消</fm-btn>
      </div>
    </fm-modal>
    <fm-form-dialog
      form-title="新文章"
      submitBtnLabel="下一步"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <!-- <fm-form-dialog
      v-if="action"
      :form-title="tableActions.find(v => v.key === action).label"
      submitBtnLabel="确定"
      :open-dialog.sync="openDialogStatus"
      :form-parms="formParmsStatus"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="400px"
      @formSubmit="formSubmitStatus"
      @handleClose="openDialogStatus = false">
    </fm-form-dialog> -->
  </sign-page>
</template>

<script>
import {
  articleRequest as request,
  articleTypeRequest
} from '../../api'

import ArticleForm from './cmp/form'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ArticleForm
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: '文章类型',
        selectDatas: this.articleTypeList.map(v => {
          return {
            key: v.id,
            label: v.name
          }
        }),
        key: 'articleTypeId',
        check: {
          required: true
        }
      }]
    },
    // formParmsStatus () {
    //   let data = [{
    //     type: 'datePicker',
    //     label: '开始时间',
    //     key: 'startDate'
    //   },
    //   {
    //     type: 'datePicker',
    //     label: '结束时间',
    //     key: 'endDate'
    //   }]
    //   data = this.action === 'start' ? [data[0]] : [data[1]]
    //   return data
    // },
    tableActions () {
      return [{
        key: 'detail',
        label: '详细'
      },
      {
        key: 'update',
        label: '修改',
        show (data) {
          return !data.status || data.status === 'down'
        }
      },
      {
        key: 'up',
        label: '上架',
        show (data) {
          return !data.status || data.status === 'down'
        }
      },
      {
        key: 'down',
        label: '下架',
        show (data) {
          return data.status === 'up'
        }
      },
      {
        key: 'del',
        label: '删除',
        show (data) {
          return !data.status || data.status === 'down'
        }
      }]
    },
    columnList () {
      return [{
        field: 'title',
        title: '文章标题'
      },
      {
        field: 'articleTypeName',
        title: '类型'
      },
      {
        field: 'editUserName',
        title: '编辑人'
      },
      {
        field: 'pushTime',
        title: '上架时间',
        render: (h, rowData) => {
          return h('div', rowData && rowData.pushTime ? rowData.pushTime.slice(0, 10) : '-')
        }
      },
      {
        field: 'statusText',
        title: '状态'
      }]
    }
  },
  data () {
    return {
      action: null,
      articleTypeList: [],
      modal: false,
      openDialog: false,
      openDialogStatus: false,
      chooseData: {},
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  },
  created () {
    this.$store.dispatch('loadWorkerUserList')
    this.loadTypes()
  },
  methods: {
    // async formSubmitStatus (data, r) {
    //   if (this.action === 'start') {
    //     let startDate = data.startDate ? (data.startDate instanceof Date ? dateOperating.computeDay({days: 0, date: data.startDate, format: 'yy-mm-dd hh:mm:ss'}) : data.startDate) : null
    //     this.updateStatus({
    //       status: 'doing',
    //       startDate
    //     })
    //   } else if (this.action === 'end') {
    //     let endDate = data.endDate ? (data.endDate instanceof Date ? dateOperating.computeDay({days: 0, date: data.endDate, format: 'yy-mm-dd hh:mm:ss'}) : data.endDate) : null
    //     this.updateStatus({
    //       status: 'end',
    //       endDate
    //     })
    //   }
    //   r()
    //   this.openDialogStatus = false
    // },
    async formSubmit (data, r) {
      this.chooseData = data
      this.chooseData.articleType = this.articleTypeList.find(v => v.id === data.articleTypeId)
      this.openDialog = false
      this.modal = true
      r()
    },
    async loadTypes () {
      this.articleTypeList = await articleTypeRequest.get()
    },
    async loadData (parm) {
      let datas = await request.get(parm) 
      datas.forEach(v => {
        v.articleTypeName = v.articleType ? v.articleType.name : null
        v.statusText = (this.$store.getters.articleStatusList.find(v1 => v.status === v1.key) || this.$store.getters.articleStatusList[0]).label
      })
      return datas
    },
    async tableAction (parm) {
      this.chooseData = Object.assign({}, parm.data)
      if (parm.action === 'detail') {
        this.$router.push({
          name: 'hrms.article.detail',
          query: {
            type: 'manage',
            id: this.chooseData.id
          }
        })
      } else if (parm.action === 'update') {
        this.modal = true
      } else if (parm.action === 'up') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定上架该文章吗?'})
        if (result) {
          let pushTime = dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
          this.updateStatus({
            status: 'up',
            pushTime
          })
        }
      } else if (parm.action === 'down') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定下架该文章吗?'})
        if (result) {
          this.updateStatus({
            status: 'down',
            pushTime: null
          })
        }
      }
    },
    async updateStatus (parm) {
      await request.update(this.chooseData.id, parm)
      this.$refs.articleTypePage.loadData()
    },
    async save () {
      let data = await this.$refs.articleForm.getFormData()
      if (!data) {
        return
      }
      if (data.id) {
        await request.update(data.id, data)
      } else {
        await request.add(data)
      }
      this.$refs.articleTypePage.loadData()
      this.modal = false
    },
    clickTitleMenu () {
      this.chooseData = {}
      this.openDialog = true
    }
  }
}
</script>

<style scoped lang="less">
.btns {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
